import { useEffect, useState } from 'react';
import OnCourtDesktop from './OnCourtDesktop';
import RemoteDesktop from './RemoteDesktop';
import BookingSwitcher from '../BookingSwitcher';
import { BookLessonProps, LessonBookingTabEnum } from '../props';

const BookLessonDesktop: React.FC<BookLessonProps> = ({
  onCourtLessons,
  venues,
  activeLesson,
  setActiveLesson,
  onCourtLessonLoading,
  remoteLessonLoading,
  venuesLoading,
  setActiveLocation,
  activeLocation,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
  nearbyVenues,
  openLoginModal,
  remoteLessons,
  onlyFollowedCoached = false,
}) => {
  const [activeTab, setActiveTab] = useState(LessonBookingTabEnum.Remote);

  /**
   * @note Assuming this is true and preventing jumping between on-court and remote while loading happens
   */
  const isRemoteCoachingAvailable = true;

  return (
    <section id="book-lesson-desktop" className="px-12 pt-ds-2xl max-sm:hidden md:mb-20">
      <h2 className="typography-product-display text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
        {activeTab === LessonBookingTabEnum.Remote
          ? 'Book a Remote Lesson'
          : 'Book an On-Court Lesson'}
      </h2>
      {isRemoteCoachingAvailable && (
        <BookingSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      <div className="mt-ds-2xl rounded-2xl border border-color-border-input-lightmode p-ds-xl shadow-sm dark:border-color-border-input-darkmode">
        <OnCourtDesktop
          onCourtLessons={onCourtLessons}
          venues={venues}
          activeLesson={activeLesson}
          setActiveLesson={setActiveLesson}
          lessonsLoading={onCourtLessonLoading}
          venuesLoading={venuesLoading}
          setActiveLocation={setActiveLocation}
          activeLocation={activeLocation}
          activeOwnerLessons={activeOwnerLessons}
          coachAvailability={coachAvailability}
          handleSelectSlot={handleSelectSlot}
          selectedDate={selectedDate}
          handleBookLesson={handleBookLesson}
          nearbyVenues={nearbyVenues}
          activeTab={activeTab}
          onlyFollowedCoached={onlyFollowedCoached}
        />
        {isRemoteCoachingAvailable && (
          <RemoteDesktop
            activeTab={activeTab}
            lessonsLoading={remoteLessonLoading}
            remoteLessons={remoteLessons}
            openLoginModal={openLoginModal}
            onlyFollowedCoached={onlyFollowedCoached}
          />
        )}
      </div>
    </section>
  );
};

export default BookLessonDesktop;
