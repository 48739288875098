import { useCallback, useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { AccountType } from 'constants/user';
import {
  useGetActiveOwnerLessonsByIdLazyQuery,
  useGetCoachBookingPageLazyQuery,
  useGetCoachesByGeoLazyQuery,
  useGetFollowedCoachesLazyQuery,
  useGetRemoteCoachesByGeoLazyQuery,
  useGetUniqueCoachVenueCourtsLazyQuery,
  useGetVenuesByGeoLazyQuery,
} from 'types/generated/client';
import {
  VenueAddress,
  formatCoachVenueData,
  formatNearByVenueData,
} from 'utils/shared/coachBuilder';
import { milesToMeters } from 'utils/shared/geo/milesToMeters';
import { useAuthModals } from 'hooks/useAuthModals';
import { useGeoLocation } from 'hooks/useGeoLocation';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import SafeAreaPage from 'layouts/SafeAreaPage';
import Footer from 'components/Footer';
import Head from 'components/utilities/Head';
import PopppinsFont from 'components/utilities/PoppinsFont';
import BookLessonDesktop from './BookLessonDesktop';
import BookLessonMobile from './BookLessonMobile';
import ConnectionSection from './ConnectionSection';
import DiscoverMoreSection from './DiscoverMoreSection';
import HeroSection from './HeroSection';
import MetricSection from './MetricSection';
import PaymentModal from './PaymentModal';
import RemoteCoachingSection from './RemoteCoachingSection';
import { CombinedCoachType, HandleSelectSlotFnType, PlayerHomeProps } from './props';
import { formatCoaches } from './utils';

const DEFAULT_COACH_DISTANCE_MILES = 50;
const DEFAULT_VENUES_DISTANCE_MILES = 20;

const PlayerHome: React.FC<PlayerHomeProps> = ({ isServerPlayer, isServerAnonymous }) => {
  const { centerLatitude, centerLongitude, position, getEstimatedLocation } = useGeoLocation();
  const { ModalSignup, ModalLogin, openSignupModal, openLoginModal } = useAuthModals();
  const { user } = useGetCurrentUser();

  const isLoggedOut = !user;
  const shouldShowHero = isLoggedOut && isServerAnonymous;
  const paymentModal = useModal();

  const [activeLesson, setActiveLesson] = useState<CombinedCoachType | null>(null);
  const [activeLocation, setActiveLocation] = useState<VenueAddress | null>(null);
  const [registerAccountType, setRegisterAccountType] = useState<AccountType | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [getCoachesByGeoLazyQuery, { data: lessonsData, loading: lessonsLoading }] =
    useGetCoachesByGeoLazyQuery();

  const [
    getFollowedCoachesLazyQuery,
    { data: followedLessonData, loading: followedLessonLoading },
  ] = useGetFollowedCoachesLazyQuery();

  const [getActiveOwnerLessonsByIdQuery, { data: activeOwnerlessonsData }] =
    useGetActiveOwnerLessonsByIdLazyQuery();

  const [getCoachBookingPageLazyQuery, { data: coachUpdatedData }] =
    useGetCoachBookingPageLazyQuery();

  const [getUniqueCoachVenueCourts, { data: uniqueCoachVenueCourtsData, loading: venuesLoading }] =
    useGetUniqueCoachVenueCourtsLazyQuery();

  const [getVenuesByGeoLazyQuery, { data: nearbyVenuesData }] = useGetVenuesByGeoLazyQuery();

  const [getRemoteCoachesByGeoQuery, { data: remoteCoachesData, loading: remoteCoachesLoading }] =
    useGetRemoteCoachesByGeoLazyQuery();

  const onCourtCoaches = useMemo(() => {
    const followedOnCourtCoaches = (followedLessonData?.userProfiles || []).filter(
      (followedLesson) => followedLesson.priceUnitAmountCoachDefault,
    );
    if (followedOnCourtCoaches.length) {
      return formatCoaches({
        coaches: followedOnCourtCoaches,
        centerLatitude: centerLatitude || 0,
        centerLongitude: centerLongitude || 0,
      });
    } else {
      const coachesOnPlatform = lessonsData?.userProfiles || [];
      const coachStartProfiles = lessonsData?.coachStarterProfiles || [];

      return formatCoaches({
        coaches: [...coachesOnPlatform, ...coachStartProfiles],
        centerLatitude: centerLatitude || 0,
        centerLongitude: centerLongitude || 0,
      });
    }
  }, [lessonsData, centerLatitude, centerLongitude, followedLessonData]);

  const remoteCoaches = useMemo(() => {
    const followedRemoteCoaches = (followedLessonData?.userProfiles || []).filter(
      (followedLesson) => followedLesson.priceUnitAmountRemoteCoachDefault,
    );
    const followedRemoteCoachesIds = followedRemoteCoaches.map((coach) => coach.id);
    const remoteCoachesWithFollowedFiltered = (remoteCoachesData?.userProfiles || []).filter(
      (remoteCoach) => !followedRemoteCoachesIds.includes(remoteCoach.id),
    );
    const remoteCoaches = formatCoaches({
      coaches: [
        ...followedRemoteCoaches,
        ...(remoteCoachesWithFollowedFiltered || []).sort(
          (a, b) =>
            (b.followersAggregate?.aggregate?.count || 0) -
            (a.followersAggregate?.aggregate?.count || 0),
        ),
      ],
      centerLatitude: centerLatitude || 0,
      centerLongitude: centerLongitude || 0,
      isPreventSorting: true,
    });

    return remoteCoaches;
  }, [remoteCoachesData, followedLessonData]);

  const venues = useMemo(() => {
    if (activeLesson && uniqueCoachVenueCourtsData?.coachAvailability) {
      return formatCoachVenueData(uniqueCoachVenueCourtsData?.coachAvailability);
    }
    return [];
  }, [uniqueCoachVenueCourtsData?.coachAvailability, activeLesson]);

  const nearByVenues = useMemo(() => {
    if (nearbyVenuesData?.venues?.length) {
      return formatNearByVenueData(nearbyVenuesData?.venues);
    }
    return [];
  }, [nearbyVenuesData]);

  const coachAvailability = useMemo(() => {
    if (activeLocation) {
      return (
        coachUpdatedData?.userProfiles?.[0]?.coachAvailability.filter(
          (availability) =>
            availability?.coachAvailabilityCustomCourt?.id === activeLocation?.id ||
            availability?.coachAvailabilityVenueId?.id === activeLocation?.id,
        ) || []
      );
    }
    return [];
  }, [activeLocation, coachUpdatedData]);

  const activeOwnerLessons = activeOwnerlessonsData?.lessons || [];

  const hideBookLesson =
    !(followedLessonLoading || lessonsLoading || remoteCoachesLoading) &&
    onCourtCoaches.length === 0 &&
    remoteCoaches.length === 0;

  const handleRegistration = (accountType: AccountType) => {
    setRegisterAccountType(accountType);
    openSignupModal();
  };

  const handleSelectSlot = useCallback<HandleSelectSlotFnType>((date) => {
    setSelectedDate(date);
  }, []);

  const handleBookLesson = useCallback(() => {
    paymentModal.openModal();
  }, [paymentModal]);

  const handleBookAnotherLesson = useCallback(() => {
    setSelectedDate(null);
    paymentModal.closeModal();
  }, [paymentModal]);

  const handlePaymentSuccess = async () => {
    await getCoachBookingPageLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: activeLesson?.id,
        username: activeLesson?.id,
        startDateTime: new Date().toISOString(),
      },
    });
  };

  useEffect(() => {
    if (user?.id) {
      getFollowedCoachesLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: user?.id,
        },
      });
    } else {
      const getCoaches = async () => {
        try {
          await getCoachesByGeoLazyQuery({
            fetchPolicy: 'network-only',
            variables: {
              distance: milesToMeters(DEFAULT_COACH_DISTANCE_MILES),
              from: {
                type: 'Point',
                coordinates: [position!.longitude, position!.latitude],
              },
            },
          });
        } catch (err) {
          captureException(err);
        }
      };

      position && getCoaches();
    }
  }, [user?.id, position]);

  useEffect(() => {
    onCourtCoaches.length > 0 && setActiveLesson(onCourtCoaches[0]);
  }, [onCourtCoaches]);

  useEffect(() => {
    if (venues.length > 0) setActiveLocation(venues[0]);
    else if (venues.length === 0) {
      setActiveLocation(null);
    }
  }, [venues]);

  useEffect(() => {
    const getCoaches = async () => {
      try {
        await getCoachesByGeoLazyQuery({
          fetchPolicy: 'network-only',
          variables: {
            distance: milesToMeters(DEFAULT_COACH_DISTANCE_MILES),
            from: {
              type: 'Point',
              coordinates: [position!.longitude, position!.latitude],
            },
          },
        });
      } catch (err) {
        captureException(err);
      }
    };

    position && followedLessonData?.userProfiles.length === 0 && getCoaches();
  }, [followedLessonData, position]);

  useEffect(() => {
    const getVenues = async () => {
      try {
        await getVenuesByGeoLazyQuery({
          fetchPolicy: 'network-only',
          variables: {
            distance: milesToMeters(DEFAULT_VENUES_DISTANCE_MILES),
            from: {
              type: 'Point',
              coordinates: [position!.longitude, position!.latitude],
            },
          },
        });
      } catch (err) {
        captureException(err);
      }
    };

    position && getVenues();
  }, [getVenuesByGeoLazyQuery, position]);

  useEffect(() => {
    const init = async () => {
      await getUniqueCoachVenueCourts({
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: activeLesson?.id,
        },
      });
      setSelectedDate(null);
    };
    activeLesson && init();
  }, [activeLesson]);

  useEffect(() => {
    const init = async () => {
      await getActiveOwnerLessonsByIdQuery({
        variables: { ownerUserId: activeLesson?.id },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
      });

      await getCoachBookingPageLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          id: activeLesson?.id,
          username: activeLesson?.id,
          startDateTime: new Date().toISOString(),
        },
      });
    };
    activeLesson?.id && init();
  }, [activeLesson]);

  useEffect(() => {
    if (!position) {
      getEstimatedLocation();
    }
  }, [position]);

  useEffect(() => {
    const init = async () => {
      try {
        await getRemoteCoachesByGeoQuery({});
      } catch (err) {
        captureException(err);
      }
    };
    init();
  }, [position, getRemoteCoachesByGeoQuery]);

  return (
    <>
      <PopppinsFont />
      <Head
        title="The Platform Powering Pickleball and Tennis"
        description="Take lessons, create round robins, register for tournaments, find courts near you, and manage your community. Pickleball and tennis run through Bounce."
      />
      <SafeAreaPage isShowTopNav isHideSidebar>
        <main>
          {shouldShowHero && <HeroSection />}
          {!hideBookLesson && (
            <>
              <BookLessonDesktop
                setActiveLocation={setActiveLocation}
                activeLocation={activeLocation}
                setActiveLesson={setActiveLesson}
                activeLesson={activeLesson}
                remoteLessonLoading={remoteCoachesLoading || followedLessonLoading}
                onCourtLessonLoading={lessonsLoading || followedLessonLoading}
                venuesLoading={venuesLoading}
                coachAvailability={coachAvailability}
                activeOwnerLessons={activeOwnerLessons}
                handleSelectSlot={handleSelectSlot}
                onCourtLessons={onCourtCoaches}
                remoteLessons={remoteCoaches}
                venues={venues}
                selectedDate={selectedDate}
                handleBookLesson={handleBookLesson}
                nearbyVenues={(nearByVenues || []).slice(0, 5)}
                openLoginModal={openLoginModal}
                onlyFollowedCoached={!!followedLessonData?.userProfiles?.length}
              />
              <BookLessonMobile
                setActiveLocation={setActiveLocation}
                coachAvailability={coachAvailability}
                activeOwnerLessons={activeOwnerLessons}
                activeLocation={activeLocation}
                setActiveLesson={setActiveLesson}
                activeLesson={activeLesson}
                remoteLessonLoading={remoteCoachesLoading || followedLessonLoading}
                onCourtLessonLoading={lessonsLoading || followedLessonLoading}
                venuesLoading={venuesLoading}
                handleSelectSlot={handleSelectSlot}
                onCourtLessons={onCourtCoaches}
                remoteLessons={remoteCoaches}
                venues={venues}
                selectedDate={selectedDate}
                handleBookLesson={handleBookLesson}
                nearbyVenues={(nearByVenues || []).slice(0, 5)}
                openLoginModal={openLoginModal}
                onlyFollowedCoached={!!followedLessonData?.userProfiles?.length}
              />
            </>
          )}
          <RemoteCoachingSection />
          {shouldShowHero && <MetricSection />}
          <DiscoverMoreSection />
        </main>
        <Footer />
      </SafeAreaPage>
      <ModalSignup isShowLoginLink defaultAccountType={registerAccountType!} />
      <ModalLogin isShowSignupLink />
      <PaymentModal
        selectedDate={selectedDate}
        activeLocation={activeLocation}
        activeLesson={activeLesson}
        isOpen={paymentModal.isOpen}
        handleClose={paymentModal.closeModal}
        onPaymentSuccess={handlePaymentSuccess}
        handleBookAnotherLesson={handleBookAnotherLesson}
      />
    </>
  );
};

export default PlayerHome;
