import { useEffect, useState } from 'react';
import { LESSONS_PAGE, REMOTE_COACHES_PAGE } from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import Link from 'components/Link';
import OnCourtMobile from './OnCourtMobile';
import RemoteMobile from './RemoteMobile';
import BookingSwitcher from '../BookingSwitcher';
import { BookLessonProps, LessonBookingTabEnum } from '../props';

const BookLessonMobile: React.FC<BookLessonProps> = ({
  onCourtLessons,
  remoteLessons,
  remoteLessonLoading,
  onCourtLessonLoading,
  setActiveLesson,
  venues,
  setActiveLocation,
  activeLocation,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
  nearbyVenues,
  venuesLoading,
  activeLesson,
}) => {
  const [activeTab, setActiveTab] = useState(LessonBookingTabEnum.Remote);

  /**
   * @note Assuming this is true and preventing jumping between on-court and remote while loading happens
   */
  const isRemoteCoachingAvailable = true;

  return (
    <div
      id="book-lesson-mobile"
      className="mt-8 rounded-2xl border bg-color-bg-lightmode-primary py-8 shadow-sm dark:bg-color-bg-darkmode-primary sm:hidden"
    >
      <div className="mb-7 flex justify-between gap-1 px-20 max-sm:px-4">
        <h2 className="typography-product-subheading">
          {activeTab === LessonBookingTabEnum.Remote
            ? 'Book a Remote Lesson'
            : 'Book an On-Court Lesson'}
        </h2>
        <Link
          href={activeTab === LessonBookingTabEnum.Remote ? REMOTE_COACHES_PAGE : LESSONS_PAGE}
          className="typography-product-button-label-small flex cursor-pointer select-none items-center gap-1 text-color-brand-primary"
        >
          View all Coaches <ArrowRight className="h-3 w-3" />
        </Link>
      </div>
      {isRemoteCoachingAvailable && (
        <div className="px-4">
          <BookingSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
      )}
      <div className="mt-7">
        <OnCourtMobile
          onCourtLessons={onCourtLessons}
          lessonsLoading={onCourtLessonLoading}
          setActiveLesson={setActiveLesson}
          venues={venues}
          setActiveLocation={setActiveLocation}
          activeLocation={activeLocation}
          activeOwnerLessons={activeOwnerLessons}
          coachAvailability={coachAvailability}
          handleSelectSlot={handleSelectSlot}
          selectedDate={selectedDate}
          handleBookLesson={handleBookLesson}
          nearbyVenues={nearbyVenues}
          venuesLoading={venuesLoading}
          activeLesson={activeLesson}
          activeTab={activeTab}
        />
        <RemoteMobile
          remoteLessons={remoteLessons}
          lessonsLoading={remoteLessonLoading}
          setActiveLesson={setActiveLesson}
          activeTab={activeTab}
          activeLesson={activeLesson}
        />
      </div>
    </div>
  );
};

export default BookLessonMobile;
