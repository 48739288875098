import { COACHES_LANDING_PAGE } from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import Check from 'svg/Check';
import Button from 'components/Button';
import Link from 'components/Link';
import { HeroImagesWrapper } from './styles';

const HeroSection: React.FC = () => {
  const learnMoreJsx = (
    <>
      <p className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight">
        Are you a coach?
      </p>
      <Link
        href={COACHES_LANDING_PAGE}
        className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight  flex items-center gap-1 text-color-text-brand"
      >
        <span className="shrink-0">Learn More</span>
        <ArrowRight className="ml-1 h-5 w-5 shrink-0" />
      </Link>
    </>
  );

  const getCheckIconWithText = (text: string) => (
    <div className="flex items-center gap-3">
      <div className="flex items-center justify-center rounded-full bg-color-bg-lightmode-primary p-2 shadow focus:outline-none focus:ring-2 focus:ring-color-brand-primary dark:bg-color-bg-darkmode-primary">
        <Check className="h-4 w-4 text-color-text-brand dark:text-color-text-brand" />
      </div>
      <span className="typography-informative-body-superhighlight ">{text}</span>
    </div>
  );

  const handleScrollToBooking = (isMobile = false) => {
    document.getElementById(`book-lesson-${isMobile ? 'mobile' : 'desktop'}`)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <section className="mx-auto w-full sm:container max-sm:py-12">
      <div className="flex items-center gap-4 max-sm:flex-col max-sm:gap-10">
        <div className="flex items-center px-4 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary  sm:flex-[0.6] ">
          <div className="flex max-w-[36rem] flex-col ">
            <h1 className="max-sm:typography-informative-subheading typography-informative-display-secondary-mobile max-sm:text-pretty max-sm:text-center">
              Pickleball and tennis lessons{' '}
              <mark className="max-sm:typography-informative-body-superhighlight typography-informative-display-mega-primary-mobile bg-transparent max-sm:text-2xl">
                on-demand
              </mark>
            </h1>
            <p className="max-sm:typography-product-text-card-mobile typography-informative-subheading-compact mt-2 max-sm:mx-auto max-sm:max-w-56 max-sm:text-center">
              Discover world-class coaches and book lessons with them directly.
            </p>

            <div className="mt-8 flex items-center gap-6 max-sm:justify-center">
              {getCheckIconWithText('Remote')}
              {getCheckIconWithText('On-Court')}
            </div>

            <Button
              sizeDesktop="lg"
              sizeMobile="md"
              variant="brand"
              className="mt-8 min-h-[2.625rem] w-fit max-sm:hidden"
              onClick={() => handleScrollToBooking()}
            >
              Book Lessons
            </Button>
            <div className="mt-20 flex gap-4 max-sm:hidden">{learnMoreJsx}</div>
          </div>
        </div>
        <HeroImagesWrapper className="max-sm:h-[20rem] max-sm:px-10 max-sm:pt-8 sm:h-[40rem] sm:flex-[0.5] sm:pt-16">
          <div className="relative">
            <img src={'/images/players/hero-image-1.png'} alt="hero-section-1" loading="lazy" />
            <img
              src={'/images/players/hero-image-2.png'}
              className="max-w-[40%] max-sm:max-w-[25%]"
              alt="hero-section-2"
              loading="lazy"
            />
          </div>
        </HeroImagesWrapper>
      </div>
      <div className="mt-12 place-items-center max-sm:grid sm:hidden">
        <Button
          sizeDesktop="lg"
          sizeMobile="md"
          variant="brand"
          className="min-h-[2.625rem] w-fit"
          onClick={() => handleScrollToBooking(true)}
        >
          Book Lessons
        </Button>
        <div className="mt-20 flex gap-4 max-sm:mt-5">{learnMoreJsx}</div>
      </div>
    </section>
  );
};

export default HeroSection;
