import { useEffect, useState } from 'react';
import { LessonBookingTabEnum, OnCourtDesktopProps } from 'screens/PlayerHome/props';
import Stepper from 'screens/TournamentsBuilder/components/Stepper';
import classNames from 'styles/utils/classNames';
import ChooseLocationStep from './ChooseLocationStep';
import ChooseTimeSlotStep from './ChooseTimeSlotStep';
import SelectCoachStep from '../SelectCoachStep';

const OnCourtDesktop: React.FC<OnCourtDesktopProps> = ({
  lessonsLoading,
  onCourtLessons,
  setActiveLesson,
  setActiveLocation,
  venues,
  selectedDate,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  nearbyVenues,
  venuesLoading,
  activeLocation,
  handleBookLesson,
  activeLesson,
  activeTab,
  onlyFollowedCoached = false,
}) => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    setActiveStep(selectedDate ? 3 : activeLocation ? 2 : activeLesson ? 1 : 0);
  }, [selectedDate, activeLocation, activeLesson]);

  return (
    <div className={classNames('hidden', activeTab === LessonBookingTabEnum.OnCourt && '!block')}>
      <div className="flex justify-center">
        <Stepper
          steps={[
            { title: 'Select a coach', description: '' },
            { title: 'Choose a location', description: '' },
            { title: 'Pick a date', description: '' },
          ]}
          currentStep={activeStep}
          className="max-w-full"
        />
      </div>
      <div className="mt-7 flex gap-8 max-xl:flex-col">
        <SelectCoachStep
          loading={lessonsLoading}
          lessons={onCourtLessons}
          handleLessonSelect={(lesson) => setActiveLesson(lesson)}
          activeLesson={activeLesson}
          activeTab={activeTab}
          onlyFollowedCoached={onlyFollowedCoached}
        />
        <ChooseLocationStep
          setActiveLocation={setActiveLocation}
          activeLocation={activeLocation}
          venues={venues}
          venuesLoading={venuesLoading}
          nearbyVenues={nearbyVenues}
        />
        <ChooseTimeSlotStep
          activeOwnerLessons={activeOwnerLessons}
          coachAvailability={coachAvailability}
          handleSelectSlot={handleSelectSlot}
          selectedDate={selectedDate}
          handleBookLesson={handleBookLesson}
        />
      </div>
    </div>
  );
};

export default OnCourtDesktop;
